import { useEffect, React } from "react";
import CardProduct from "./CardProduct";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useDispatch, useSelector } from "react-redux";
import { loadProductsFeatured } from "state/products";
import CircularIndeterminate from "./CircularIndeterminate";

// nodejs library that concatenates classes
import classNames from "classnames";

const getItensCard = (itemMakerObj, baseURL, index) => {
  return (
    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
      <CardProduct {...itemMakerObj} baseURL={baseURL} />
    </Grid>
  );
};

// @material-ui/core components
// import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";

const useStylesEcommerce = makeStyles(styles);

export default function ProductsFeaturedComponent() {
  const classes = useStylesEcommerce();

  const dispatch = useDispatch();

  const products = useSelector((state) => state.products);
  const baseURL = products.baseURL;

  useEffect(() => {
    dispatch(loadProductsFeatured());
  }, [dispatch]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer container spacing={1}>
          <GridContainer xs={12}>
            <GridItem
              className={classNames(
                classes.mrAuto,
                classes.mlAuto,
                classes.textCenter,
                classes.aboutDescription,
                classes.textCenter
              )}
            >
              <Box m={8}>
                <h3 className={classes.title}>PRODUTOS RECOMENDADOS</h3>
              </Box>
            </GridItem>
          </GridContainer>

          {products.loading ? (
            <Box m={15}>
              <CircularIndeterminate />{" "}
            </Box>
          ) : (
            ""
          )}

          {products.featured.map(function (itemMakerObj, index) {
            return getItensCard(itemMakerObj, baseURL, index);
          })}
        </GridContainer>
      </div>
    </div>
  );
}
