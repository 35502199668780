/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import moment from "moment";
const slice = createSlice({
  name: "filters",
  initialState: {
    categories: [],
    brands: [],
    selectedCategories: [],
    selectedBrands: [],
    lowerPrice: 0,
    biggestPrice: 9999,
    loading: false,
    lastFetchCategories: null,
    lastFetchBrands: null,
    reset: false,
  },
  reducers: {
    filterRequested: (filters, action) => {
      filters.loading = true;
    },

    categoriesReceived: (filters, action) => {
      console.log("action");

      filters.categories = action.payload;
      filters.loading = false;
      filters.lastFetchCategories = Date.now();
    },

    brandsReceived: (filters, action) => {
      filters.brands = action.payload;
      filters.loading = false;
      filters.lastFetchBrands = Date.now();
    },

    categoriesSelected: (filters, action) => {
      filters.selectedCategories = action.payload.catSelected;
    },

    brandsSelected: (filters, action) => {
      //console.log(action.payload);
      filters.selectedBrands = action.payload.bSelected;
    },
    rangePrice: (filters, action) => {
      //console.log(action.payload);
      filters.lowerPrice = action.payload.lowerPrice;
      filters.biggestPrice = action.payload.biggestPrice;
    },
    setReset: (filters, action) => {
      filters.reset = action.payload.status;
    },

    filterRequestFailed: (filters, action) => {
      filters.loading = false;
    },
  },
});

export default slice.reducer;

const {
  filterRequested,
  categoriesReceived,
  brandsReceived,
  filterRequestFailed,
  categoriesSelected,
  brandsSelected,
  rangePrice,
  setReset,
} = slice.actions;

const urlCategories = "/categories";
const urlBrands = "/brands";

export const loadCategories = () => (dispatch, getState) => {
  const { lastFetchCategories } = getState().filters;
  const diffInMinutes = moment().diff(lastFetchCategories, "minute");
  if (diffInMinutes < 10) {
    return;
  }
  console.log("loadCategories");
  let response = dispatch(
    apiCallBegan({
      url: urlCategories,
      onStart: filterRequested.type,
      onSuccess: categoriesReceived.type,
      onError: filterRequestFailed.type,
    })
  );

  return response;
};

export const loadBrands = () => (dispatch, getState) => {
  const { lastFetchBrands } = getState().filters;
  const diffInMinutes = moment().diff(lastFetchBrands, "minute");
  if (diffInMinutes < 10) {
    return;
  }

  let response = dispatch(
    apiCallBegan({
      url: urlBrands,
      onStart: filterRequested.type,
      onSuccess: brandsReceived.type,
      onError: filterRequestFailed.type,
    })
  );

  return response;
};

export const updateCategoriesSelected =
  ({ catSelected }) =>
  (dispatch, getState) => {
    dispatch(categoriesSelected({ catSelected }));
  };

export const updateBrandsSelected =
  ({ bSelected }) =>
  (dispatch, getState) => {
    dispatch(brandsSelected({ bSelected }));
  };

export const updateRangePrice =
  ({ lowerPrice, biggestPrice }) =>
  (dispatch, getState) => {
    dispatch(rangePrice({ lowerPrice, biggestPrice }));
  };

export const setResetStatus =
  ({ status }) =>
  (dispatch, getState) => {
    dispatch(setReset({ status }));
  };
