/* eslint-disable no-unused-vars */
import { useEffect, React } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Link } from "react-router-dom";
import { updateCategoriesSelected } from "state/filters";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
/* import Typography from "@material-ui/core/Typography";*/
import Box from "@material-ui/core/Box";
import classNames from "classnames";
import { Markup } from "interweave";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

import { useDispatch, useSelector } from "react-redux";
import { loadCategoriesFeatured } from "state/products";

export default function CategoriesFeaturedComponent() {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);
  const products = useSelector((state) => state.products);
  const baseURL = products.baseURL;

  useEffect(() => {
    dispatch(loadCategoriesFeatured());
  }, [dispatch]);

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <GridItem
              className={classNames(
                classes.mrAuto,
                classes.mlAuto,
                classes.textCenter,
                classes.aboutDescription,
                classes.textCenter
              )}
            >
              <Box m={5}>
                <h3 className={classes.title}>CATEGORIAS MAIS RELEVANTES</h3>
              </Box>
            </GridItem>
            <GridContainer>
              {products.categoriesFeatured.map(function (category, index) {
                return (
                  <GridItem key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card blog>
                      <CardHeader imageCatFeatured>
                        <Link
                          to="/products"
                          onClick={() => {
                            let newChecked = [category.id];
                            dispatch(
                              updateCategoriesSelected({
                                catSelected: newChecked,
                              })
                            );
                          }}
                        >
                          <img
                            src={`${baseURL}${category.thumbnail}`}
                            alt="..."
                          />{" "}
                        </Link>

                        {
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage:
                                "url(" +
                                `${baseURL}${category.thumbnail}` +
                                ")",
                              opacity: "0.5",
                            }}
                          />
                        }
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>
                          <a href="">{category.name.pt}</a>
                        </h4>
                        {/*  <p className={classes.description}>
                          {category.description.pt}
                        </p> */}
                        <Markup content={category.description.pt} />
                      </CardBody>
                    </Card>
                  </GridItem>
                );
              })}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
