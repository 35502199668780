/* eslint-disable no-unused-vars */
import { useState, useEffect, React } from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import { useDispatch, useSelector } from "react-redux";
import {
  searchProducts,
  revertSearchProducts,
  setStringSearch,
} from "state/products";
var flag = 0;

export default function SearchInput(props) {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);

  const [searchTerm, setSearchTerm] = useState(
    props.value != null ? props.value : null
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let val = searchTerm;
      if (val == "") {
        val = null;

        if (
          filters.selectedCategories.length > 0 ||
          filters.selectedBrands.length > 0
        ) {
          dispatch(setStringSearch({ value: val }));
          dispatch(searchProducts({ value: null }));
        } else {
          dispatch(revertSearchProducts());
          dispatch(setStringSearch({ value: null }));
        }
      }
      if (val != null) {
        dispatch(searchProducts({ value: val }));
        dispatch(setStringSearch({ value: val }));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleClose = () => {
    setSearchTerm("");
    dispatch(setStringSearch({ value: null }));
    dispatch(revertSearchProducts());
  };

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        hd: 1280,
        uxga: 1600,
        desktop: 1601,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: {
            mobile: "100%",
            laptop: "90%",
          },
          marginTop: {
            mobile: 0,
            laptop: 5,
          },
          marginBottom: {
            mobile: 3,
            laptop: 5,
          },
          marginLeft: {
            laptop: 3,
          },
          marginRight: {
            laptop: 3,
          },
        }}
      >
        <div>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              //className={classes.root}
              id="standard-basic"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              color="secondary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <Close />
                  </IconButton>
                ),
              }}
              placeholder="Pesquise aqui!"
              variant="standard"
            />
          </FormControl>
        </div>
      </Box>
    </ThemeProvider>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
};
