import { useEffect, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import { loadBanners } from "state/banners";
import MediaQuery from "react-responsive";

import bannerStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import configData from "config.json";

const useStylesHeader = makeStyles(bannerStyle);

const Slider = () => {
  const classes = useStylesHeader();
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners.banners);

  useEffect(() => {
    dispatch(loadBanners());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    swipeToSlide: true,
    // centerMode: true,

    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    objectFit: "cover",
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      {banners.data && banners.data.home ? (
        <Carousel {...settings}>
          {banners.data.home.images.map((stuff, i) => {
            return (
              <div key={i}>
                <MediaQuery query="(max-device-width: 767px)">
                  <div
                    className={classes.pageHeader}
                    style={{
                      backgroundImage: `url("${configData.SERVER_URL}${stuff.mobile}")`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </MediaQuery>
                <MediaQuery query="(min-device-width: 768px)">
                  <div
                    className={classes.pageHeader}
                    style={{
                      backgroundImage: `url("${configData.SERVER_URL}${stuff.desktop}")`,

                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </MediaQuery>
              </div>
            );
          })}
        </Carousel>
      ) : (
        ""
      )}
    </div>
  );
};

export default Slider;
