/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "about",
  initialState: {
    phone: "",
    privacy_policies: "",
    therms_conditions: "",
    address: "",
    presentation: "",
    email: "",
    loading: false,
    lastFetch: null,
  },
  reducers: {
    aboutRequested: (about, action) => {
      about.loading = true;
    },

    aboutReceived: (about, action) => {
      about.name = action.payload.data.name;
      about.subDesc = action.payload.data.small_description.pt;
      about.phone = action.payload.data.phone;
      about.privacy_policies = action.payload.data.privacy_policies.pt;
      about.therms_conditions = action.payload.data.therms_conditions.pt;
      about.address = action.payload.data.address;
      about.email = action.payload.data.email;
      about.presentation = action.payload.data.presentation.pt;
      about.loading = false;
      about.lastFetch = Date.now();
    },

    aboutRequestFailed: (about, action) => {
      about.loading = false;
    },
  },
});

export default slice.reducer;

const { aboutRequested, aboutReceived, aboutRequestFailed } = slice.actions;

const url = "/about";

export const loadAbout = () => (dispatch, getState) => {
  const { lastFetchCategories } = getState().filters;

  const diffInMinutes = moment().diff(lastFetchCategories, "minute");
  if (diffInMinutes < 10) {
    return;
  }
  let response = dispatch(
    apiCallBegan({
      url: url,
      onStart: aboutRequested.type,
      onSuccess: aboutReceived.type,
      onError: aboutRequestFailed.type,
    })
  );

  return response;
};
