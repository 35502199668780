/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { submitOrder } from "state/cart";
import { setShippingMethods } from "state/cart";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
const useStyles = makeStyles(contactsStyle);
import MediaQuery from "react-responsive";

import Check from "@material-ui/icons/Check";
const defaultValues = {
  name: "",
  email: "",
  phone: "",
  address: "",
  shipping_method_id: null,
};

const useStylesSelect = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function OrderForm() {
  const [formValues, setFormValues] = useState(defaultValues);
  const [shippingMethod, setShippingMethod] = React.useState("");
  const classesSelect = useStylesSelect();

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChange = (event) => {
    setShippingMethod(event.target.value);
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    dispatch(setShippingMethods({ value: value }));
  };

  const [checked, setChecked] = React.useState([]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const classes = useStyles();
  const cart = useSelector((state) => state.cart);

  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log(formValues);
    dispatch(submitOrder({ data: formValues }));
  };

  return (
    <>
      <MediaQuery query="(max-device-width: 767px)">
        <form onSubmit={handleSubmit} md={8}>
          <Grid container justify="center" direction="column">
            <Grid item>
              <Box>
                <Box m={1}>
                  <Typography variant="h4" align="center">
                    Registe a sua encomenda
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography variant="body2" align="justify">
                    Registe a sua encomenda através desde formulário, vamos
                    entrar em contacto logo que possível, agradecemos desde já a
                    preferência.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  width: "95%",
                  margin: "10px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="name-input"
                  name="name"
                  label="Nome"
                  fullWidth
                  type="text"
                  required
                  //   variant="filled"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  margin: "10px",
                  width: "95%",
                }}
              >
                <TextField
                  variant="outlined"
                  id="address-input"
                  name="address"
                  label="Morada"
                  fullWidth
                  type="text"
                  value={formValues.address}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  margin: "10px",
                  width: {
                    mobile: "95%",
                    laptop: "500px",
                  },
                  maxWidth: "100%",
                }}
              >
                <TextField
                  variant="outlined"
                  id="email-input"
                  name="email"
                  label="Email"
                  fullWidth
                  required
                  type="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  width: "95%",
                  margin: "10px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="phone-input"
                  name="phone"
                  label="Contacto"
                  fullWidth
                  required
                  type="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>

            {cart.shippingMethods ? (
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    margin: "10px",
                    width: "95%",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classesSelect.formControl}
                  >
                    <Select
                      value={shippingMethod}
                      required
                      onChange={handleChange}
                      displayEmpty
                      name="shipping_method_id"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Métodos de envio</em>
                      </MenuItem>

                      {cart.shippingMethods.map((shippingMethod) => (
                        <MenuItem
                          key={shippingMethod.id}
                          value={shippingMethod.id}
                        >
                          {cart.total - shippingMethod.price <
                          shippingMethod.free_from
                            ? shippingMethod.name +
                              " (" +
                              shippingMethod.price +
                              "€ - " +
                              shippingMethod.delivery_time +
                              ")"
                            : shippingMethod.name +
                              " - (Gratuito) - " +
                              shippingMethod.delivery_time}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="row"
              >
                <Grid item>
                  <Box m={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          required
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Não sou um robot"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box m={3}>
                    <Button variant="contained" color="secondary" type="submit">
                      Encomendar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box m={4}>
                <Typography variant="body2" align="center">
                  *Será enviado o código de confirmação e resumo da encomenda
                  via email.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </MediaQuery>
      <MediaQuery query="(min-device-width: 768px)">
        <form onSubmit={handleSubmit} md={8}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Grid item>
              <Box>
                <Box m={2}>
                  <Typography variant="h4" align="center">
                    Registe a sua encomeda
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography variant="body2" align="center">
                    Registe a sua encomenda através deste formulário, vamos
                    entrar em contacto logo que possível, agradecemos desde já a
                    preferência.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  width: "500px",
                  margin: "10px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="name-input"
                  name="name"
                  label="Nome"
                  fullWidth
                  type="text"
                  required
                  //   variant="filled"
                  value={formValues.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  margin: "10px",
                  width: "500px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="address-input"
                  name="address"
                  label="Morada"
                  fullWidth
                  type="text"
                  value={formValues.address}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  margin: "10px",
                  width: "500px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="email-input"
                  name="email"
                  label="Email"
                  fullWidth
                  required
                  type="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  margin: "10px",
                  width: "500px",
                }}
              >
                <TextField
                  variant="outlined"
                  id="phone-input"
                  name="phone"
                  label="Contacto"
                  fullWidth
                  required
                  type="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>

            {cart.shippingMethods ? (
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    margin: "10px",
                    width: "500px",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classesSelect.formControl}
                  >
                    <Select
                      value={shippingMethod}
                      required
                      onChange={handleChange}
                      displayEmpty
                      name="shipping_method_id"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Métodos de envio</em>
                      </MenuItem>
                      {cart.shippingMethods.map((shippingMethod) => (
                        <MenuItem
                          key={shippingMethod.id}
                          value={shippingMethod.id}
                        >
                          {cart.total - shippingMethod.price <
                          shippingMethod.free_from
                            ? shippingMethod.name +
                              " (" +
                              shippingMethod.price +
                              "€ - " +
                              shippingMethod.delivery_time +
                              ")"
                            : shippingMethod.name +
                              " - (Gratuito) - " +
                              shippingMethod.delivery_time}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="row"
              >
                <Grid item>
                  <Box m={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          required
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Não sou um robot"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box m={3}>
                    <Button variant="contained" color="secondary" type="submit">
                      Encomendar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box m={4}>
                <Typography variant="body2" align="center">
                  *Será enviado o código de confirmação e resumo da encomenda
                  via email.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </MediaQuery>
    </>
  );
}
