/*eslint-disable*/
import { React, useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import { secondaryColor } from "assets/jss/material-kit-pro-react.js";
import NumberFormat from "react-number-format";

// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";

import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "./HeaderCustom.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Markup } from "interweave";
import CircularIndeterminate from "./CircularIndeterminate";
import Avatar from "@material-ui/core/Avatar";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

//redux
import { useDispatch } from "react-redux";
import { addToCart } from "state/cart";
import { useSelector } from "react-redux";
import { loadProduct } from "state/products";

const useStyles = makeStyles(productStyle);

export default function ProductPage(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  /*   const [colorSelect, setColorSelect] = useState("0");
  const [sizeSelect, setSizeSelect] = useState("0"); */
  const [imagesLoading, setLoadingImage] = useState(true);

  const product = useSelector((state) => state.products.product);
  const cartProducts = useSelector((state) => state.cart.products);

  useEffect(() => {
    if (!product) {
      dispatch(loadProduct({ id: props.match.params.slug }));
    }
  }, [product]);

  if (!product) {
    return (
      <Box sx={{ mt: 55 }}>
        <CircularIndeterminate />;
      </Box>
    );
  }

  var index = -1;
  if (product) {
    const ref = product.sku;
    index = cartProducts.findIndex((product) => product.id === ref);
  }

  const addProduct = () => {
    //setCart(true);
    let urlImage = product.base_url + product.thumbnail;
    dispatch(
      addToCart({
        id: product.id,
        sku: product.sku,
        name: product.name["pt"],
        thumbnail: urlImage,
        quantity: 1,
        price: product.price,
        total_price: product.price,
      })
    );
  };

  const images = product.images;
  const linkStyle = { color: "#000", fontWeight: 500 };
  return (
    <div className={classes.productPage}>
      <Header />

      {product.banners.desktop ? (
        <Parallax
          image={product.base_url + product.banners.desktop.src}
          // filter="rose"
          className={classes.pageHeader}
        ></Parallax>
      ) : (
        ""
      )}
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div
            className={classNames(
              classes.main,
              classes.mainRaised,
              // classes.mainRaisedMTop
              product.banners.desktop ? classes.mainRaisedMTop : ""
            )}
          >
            <GridContainer>
              <GridItem md={6} sm={6}>
                {imagesLoading ? (
                  <Box sx={{ p: 30 }}>
                    <CircularIndeterminate left="0" />
                  </Box>
                ) : (
                  ""
                )}
                <div
                  style={{
                    visibility: imagesLoading ? "hidden" : "visible",
                  }}
                >
                  <ImageGallery
                    lazyload={true}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    startIndex={0}
                    items={images}
                    showThumbnails={true}
                    disableThumbnailSwipe={false}
                    thumbnailPosition={"bottom"}
                    showIndex={true}
                    showNav={false}
                    slideOnThumbnailOver={true}
                    onImageLoad={() => {
                      setLoadingImage(false);
                    }}
                    renderLeftNav={(onClick, disabled) => {
                      return (
                        <button
                          className="image-gallery-left-nav"
                          disabled={disabled}
                          onClick={onClick}
                        />
                      );
                    }}
                    renderRightNav={(onClick, disabled) => {
                      return (
                        <button
                          className="image-gallery-right-nav"
                          disabled={disabled}
                          onClick={onClick}
                        />
                      );
                    }}
                  />
                </div>
              </GridItem>

              <GridItem md={6} sm={6}>
                <h2 className={classes.title}>{product.name.pt}</h2>
                <small>{product.category_name.pt}</small>

                {product.price ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid
                        container

                        //alignItems="center"
                      >
                        <Grid item md={6} xl={5}>
                          <Box mt={product.old_price ? 2 : 4}>
                            <NumberFormat
                              value={product.price}
                              className={classes.price}
                              displayType={"text"}
                              decimalSeparator=","
                              suffix={"€"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />

                            {product.old_price ? (
                              <Box>
                                <div>
                                  <span
                                    style={{
                                      marginRight: "6px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    PVPR
                                  </span>
                                  <NumberFormat
                                    value={product.old_price}
                                    className={classes.oldPrice}
                                    displayType={"text"}
                                    decimalSeparator=","
                                    suffix={"€"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                </div>{" "}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box mt={3}>
                            {product.discount != 0 ? (
                              <Avatar
                                style={{
                                  fontWeight: "500",
                                  fontSize: "15px",

                                  backgroundColor: secondaryColor,
                                }}
                              >
                                -{product.discount}%
                              </Avatar>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        round
                        size="md"
                        color="primary"
                        onClick={addProduct}
                      >
                        Adicionar ao &nbsp;
                        <Badge
                          badgeContent={
                            index != -1 ? cartProducts[index].quantity : 0
                          }
                          color="secondary"
                        >
                          <ShoppingCart />
                        </Badge>
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Box m={1}>
                    <Typography variant="body2" align="left">
                      Preço sob Consulta
                    </Typography>
                  </Box>
                )}

                {product.description.pt == "" ? (
                  ""
                ) : (
                  <Accordion
                    active={0}
                    activeColor="secondary"
                    collapses={[
                      {
                        title: "Descrição",
                        content: <Markup content={product.description.pt} />,
                      },
                      /*   {
                      title: "Designer Information",
                      content: <>{product.small_description.pt}</>,
                    },
                    {
                      title: "Details and Care",
                      content: (
                        <ul>
                          <li>Storm and midnight-blue stretch cotton-blend</li>
                          <li>
                            Notch lapels, functioning buttoned cuffs, two front
                            flap pockets, single vent, internal pocket
                          </li>
                          <li>Two button fastening</li>
                          <li>84% cotton, 14% nylon, 2% elastane</li>
                          <li>Dry clean</li>
                        </ul>
                      ),
                    }, */
                    ]}
                  />
                )}

                {/*                 <GridContainer className={classes.pickSize}>
                  <GridItem md={6} sm={6}>
                    <label>Select color</label>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={colorSelect}
                        onChange={(event) => setColorSelect(event.target.value)}
                        inputProps={{
                          name: "colorSelect",
                          id: "color-select",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="0"
                        >
                          Rose
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          Gray
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="2"
                        >
                          White
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem md={6} sm={6}>
                    <label>Select size</label>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={sizeSelect}
                        onChange={(event) => setSizeSelect(event.target.value)}
                        inputProps={{
                          name: "sizeSelect",
                          id: "size-select",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="0"
                        >
                          Small
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          Medium
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="2"
                        >
                          Large
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer> */}
              </GridItem>
            </GridContainer>
          </div>
          {/*           <div className={classNames(classes.features, classes.textCenter)}>
            <GridContainer>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title="2 Days Delivery"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={LocalShipping}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title="Refundable Policy"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title="Popular Item"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={Favorite}
                  iconColor="rose"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div> */}
        </div>
      </div>
      <Footer
        content={
          <div>
            {/*   <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href=""
                    target="_blank"
                    className={classes.block}
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href=""
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href=""
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div> */}
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <a
                href="https://www.technotion.pt"
                target="_blank"
                style={linkStyle}
              >
                TECHNOTION
              </a>{" "}
            </div>
          </div>
        }
      />
    </div>
  );
}
