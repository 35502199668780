import { React } from "react";

import Header from "./HeaderCustom.js";
import Slider from "./Banner/Slider";
import FooterComponent from "./FooterComponent";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionProductsCustom from "views/EcommercePage/Sections/SectionProductsCustom.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";

const useStylesEcommerce = makeStyles(styles);

const ECPage = () => {
  const classesEC = useStylesEcommerce();

  return (
    <div>
      <Header />

      <Slider />

      <div className={classNames(classesEC.main, classesEC.mainRaised)}>
        <SectionProductsCustom />
      </div>

      <FooterComponent />
    </div>
  );
};

export default ECPage;
