/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import configData from "config.json";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "../HeaderCustom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import SectionDescription from "./Sections/SectionDescription";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";
import { Markup } from "interweave";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const linkStyle = { color: "#000", fontWeight: 500 };

export default function AboutUsPage() {
  const classes = useStyles();

  const about = useSelector((state) => state.about);
  const banners = useSelector((state) => state.banners.banners);
  return (
    <div>
      <Header />

      {banners.data.about ? (
        <Parallax
          image={configData.SERVER_URL + banners.data.about.images[0].desktop}
          filter="dark"
          small
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>{about.name}</h1>
                {about.subDesc ? (
                  <div className={classes.subDesc}>
                    {" "}
                    <Markup content={about.subDesc} />
                  </div>
                ) : (
                  ""
                )}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      ) : (
        ""
      )}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />

          {/* <SectionTeam /> */}
          {/*   <SectionServices />
          <SectionOffice />
          <SectionContact /> */}
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/?ref=mkpr-about-us"
                    className={classes.block}
                    target="_blank"
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-about-us"
                    className={classes.block}
                    target="_blank"
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="//blog.creative-tim.com/"
                    className={classes.block}
                    target="_blank"
                  >
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-about-us"
                    className={classes.block}
                    target="_blank"
                  >
                    Licenses
                  </a>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> by{" "}
              <a
                href="https://www.technotion.pt"
                target="_blank"
                style={linkStyle}
              >
                TECHNOTION
              </a>{" "}
            </div>
          </div>
        }
      />
    </div>
  );
}
