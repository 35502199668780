import React from "react";
import logo from "assets/img/logo_tech.png";
import { makeStyles } from "@material-ui/core/styles";
import HeaderLinks from "./HeaderLinks";
import PropTypes from "prop-types";
import Header from "components/Header/Header";
const useStylesLogo = makeStyles({
  logo: {
    maxWidth: 220,
  },
});

const HeaderCustom = (props) => {
  const classesLogo = useStylesLogo();

  return (
    <Header
      // absolute
      brand={<img src={logo} className={classesLogo.logo} />}
      color="white"
      fixed
      changeColorOnScroll={{
        height: 300,
        color: "white",
      }}
      links={<HeaderLinks cartProducts={props.cartProducts}></HeaderLinks>}
    />
  );
};

HeaderCustom.propTypes = {
  cartProducts: PropTypes.array,
};

export default HeaderCustom;
