/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import moment from "moment";
import configData from "config.json";

const slice = createSlice({
  name: "products",
  initialState: {
    list: [],
    featured: [],
    categoriesFeatured: [],
    oldList: null,
    loading: false,
    lastFetch: null,
    page: 1,
    maxPage: 1,
    perPage: 12,
    lowerPrice: 0,
    higherPrice: 9999,
    lastPage: 999,
    displayPage: 1,
    product: null,
    stringSearch: null,
    baseURL: configData.SERVER_URL,
  },
  reducers: {
    productRestart: (products, action) => {
      products.maxPage = 1;
      products.page = 1;
      products.lastPage = 998;
      products.list = [];
      products.oldList = null;
      products.lastFetch = null;
      products.product = null;
      products.displayPage = 1;
      products.stringSearch = null;
    },
    productSetDisplayPage: (products, action) => {
      products.displayPage = action.payload.displayPage;
    },

    productMaxPageAdd: (products, action) => {
      products.maxPage++;
    },
    productPageAdd: (products, action) => {
      products.page++;
    },
    productsRequested: (products, action) => {
      products.loading = true;
    },
    productsReceived: (products, action) => {
      products.list = [];
      products.oldList = null;
      products.list = action.payload.data;
      products.lastPage = action.payload.meta.last_page;
      products.loading = false;
      products.lastFetch = Date.now();
    },
    productsLoadMoreReceived: (products, action) => {
      products.list = products.list.concat(...action.payload.data);

      products.lastPage = action.payload.meta.last_page;
      products.loading = false;
      products.lastFetch = Date.now();
    },
    productReceived: (products, action) => {
      products.product = action.payload.data;
      products.loading = false;
    },
    productSearchReceived: (products, action) => {
      console.log(action.payload);
      if (action.payload) {
        if (!products.oldList) products.oldList = products.list;
        products.list = action.payload.data;
      } else products.list = [];

      products.loading = false;
    },
    productRevertSearchReceived: (products) => {
      products.list = products.oldList;
      products.oldList = null;
      products.loading = false;
    },
    productSetStringSearch: (products, action) => {
      products.stringSearch = action.payload.value;
    },

    productRangePriceReceived: (products, action) => {
      products.lowerPrice = action.payload.data.lower_price;
      products.higherPrice = action.payload.data.higher_price;
      products.loading = false;
    },

    productsRequestFailed: (products, action) => {
      products.loading = false;
    },

    productsFeaturedReceived: (products, action) => {
      if (action.payload) {
        products.featured = action.payload.data;
      } else products.featured = [];

      products.loading = false;
    },
    categoriesFeaturedReceived: (products, action) => {
      if (action.payload) {
        products.categoriesFeatured = action.payload.data;
      } else products.categoriesFeatured = [];

      products.loading = false;
    },
  },
});

export default slice.reducer;

const {
  productsRequested,
  productsReceived,
  productsRequestFailed,
  productMaxPageAdd,
  productPageAdd,
  productRestart,
  productSetDisplayPage,
  productReceived,
  productSearchReceived,
  productRevertSearchReceived,
  productsLoadMoreReceived,
  productRangePriceReceived,
  productSetStringSearch,
  productsFeaturedReceived,
  categoriesFeaturedReceived,
} = slice.actions;

const urlProducts = "/products?page=";
const urlProduct = "/products/";
const urlSearchProduct = "/products/search";
const urlRangePrice = "/products/info/prices";
const urlProductsFeatured = "/products/featured";
const urlCategoriesFeatured = "/categories/featured";

export const setDisplayPage =
  ({ displayPage }) =>
  (dispatch, getState) => {
    dispatch(productSetDisplayPage({ displayPage }));
  };

export const setStringSearch =
  ({ value }) =>
  (dispatch, getState) => {
    dispatch(productSetStringSearch({ value }));
  };

export const loadMore = () => (dispatch, getState) => {
  const { page, maxPage, displayPage } = getState().products;

  console.log("loadMore");

  //dispatch(productPageAdd());
  dispatch(loadMoreProducts());
};

export const loadProducts = () => (dispatch, getState) => {
  const { list, lastFetch, lastPage, page, displayPage, maxPage, oldList } =
    getState().products;

  console.log("loadProducts");
  if (oldList != null) dispatch(productRevertSearchReceived());

  const diffInMinutes = moment().diff(lastFetch, "minute");
  dispatch(productsRequested());
  console.log("diffInMinutes");
  console.log(diffInMinutes);

  if (diffInMinutes > 15) {
    dispatch(productRestart());
    console.log("dispatch(productRestart());");

    //return;
  } else if (page > lastPage || maxPage > displayPage) {
    console.log("page > lastPage || maxPage > displayPage");
    dispatch(productsRequestFailed());
    return;
  }

  if (list.length > 0 && maxPage < lastPage) {
    console.log("list.length");
    console.log(list.length);
    dispatch(productPageAdd());
    dispatch(productMaxPageAdd());
  }

  let pageUpdated = getState().products.page;
  console.log("dispatch");
  console.log(urlProducts + pageUpdated);
  dispatch(
    apiCallBegan({
      url: urlProducts + pageUpdated,
      onStart: productsRequested.type,
      onSuccess: productsReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const loadMoreProducts = () => (dispatch, getState) => {
  const { list, lastFetch, lastPage, page, displayPage, maxPage } =
    getState().products;
  const diffInMinutes = moment().diff(lastFetch, "minute");
  //Categorias Selecionadas.

  dispatch(productsRequested());

  if (diffInMinutes > 15) {
    dispatch(productRestart());
    console.log("dispatch(productRestart());");
  } else if (page > lastPage || maxPage >= displayPage) {
    dispatch(productsRequestFailed());
    return;
  }

  if (list.length > 0 && maxPage < lastPage) {
    console.log("loadMoreProducts - list.length");
    console.log(list.length);
    dispatch(productPageAdd());
    dispatch(productMaxPageAdd());
  }

  let pageUpdated = getState().products.page;
  dispatch(
    apiCallBegan({
      url: urlProducts + pageUpdated,
      onStart: productsRequested.type,
      onSuccess: productsLoadMoreReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const loadProduct =
  ({ id: id }) =>
  (dispatch, getState) => {
    dispatch(
      apiCallBegan({
        url: urlProduct + id,

        onStart: productsRequested.type,
        onSuccess: productReceived.type,
        onError: productsRequestFailed.type,
      })
    );
  };

export const loadRangePrice = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: urlRangePrice,

      onStart: productsRequested.type,
      onSuccess: productRangePriceReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const loadProductsFeatured = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: urlProductsFeatured,

      onStart: productsRequested.type,
      onSuccess: productsFeaturedReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const loadCategoriesFeatured = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: urlCategoriesFeatured,

      onStart: productsRequested.type,
      onSuccess: categoriesFeaturedReceived.type,
      onError: productsRequestFailed.type,
    })
  );
};

export const searchProducts =
  ({ value: value }) =>
  (dispatch, getState) => {
    const { selectedCategories, selectedBrands, lowerPrice, biggestPrice } =
      getState().filters;
    const { stringSearch } = getState().products;

    if (!value) {
      value = stringSearch;
    }

    if (!value) {
      value = "";
    }
    console.log("searchProducts - value");
    console.log(value);

    dispatch(
      apiCallBegan({
        url: urlSearchProduct,
        method: "POST",
        data: {
          name: value,
          categories: selectedCategories,
          brands: selectedBrands,
          lower_price: lowerPrice,
          higher_price: biggestPrice,
        },
        onStart: productsRequested.type,
        onSuccess: productSearchReceived.type,
        onError: productsRequestFailed.type,
      })
    );
  };

export const revertSearchProducts = () => (dispatch, getState) => {
  const { oldList } = getState().products;

  const { selectedCategories, selectedBrands } = getState().filters;

  if (selectedCategories.length > 0 || selectedBrands.length > 0) {
    dispatch(searchProducts({ value: null }));
    return;
  }

  if (oldList == null || oldList.length == 0) {
    console.log("oldList == null");
    return;
  }

  dispatch(productRevertSearchReceived());
};

export const resetProducts = () => (dispatch, getState) => {
  dispatch(productRestart());
};
