/* eslint-disable no-unused-vars */
import { useEffect, React } from "react";
import CardProduct from "./CardProduct";
import { Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";

import { useDispatch, useSelector } from "react-redux";
import { loadProducts } from "state/products";
import { searchProducts } from "state/products";

const getItensCard = (itemMakerObj, baseURL, index) => {
  return (
    <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
      <CardProduct {...itemMakerObj} baseURL={baseURL} />
    </Grid>
  );
};

const ProductsComponent = (props) => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products);
  const baseURL = products.baseURL;
  const filters = useSelector((state) => state.filters);

  useEffect(() => {
    if (filters.selectedCategories.length > 0) {
      dispatch(searchProducts({ value: "" }));
      return;
    }

    if (products.list.length == 0) {
      dispatch(loadProducts());
    } else {
      console.log("no dispatch(loadProducts());");
    }
  }, [dispatch]);

  return filters.selectedCategories.length != 0 ||
    filters.selectedBrands.length != 0 ? (
    <GridContainer container spacing={4}>
      {products.list.map(function (itemMakerObj, index) {
        return getItensCard(itemMakerObj, baseURL, index);
      })}
    </GridContainer>
  ) : (
    <GridContainer container spacing={4}>
      {products.list
        .slice(0, products.displayPage * 12)
        .map(function (itemMakerObj, index) {
          return getItensCard(itemMakerObj, baseURL, index);
        })}
    </GridContainer>
  );
};

ProductsComponent.propTypes = {};

export default ProductsComponent;
