import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const cardHeaderStyle = {
  cardHeader: {
    borderRadius: "3px",
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    border: "0",
    marginBottom: "0",
    height: "15vw",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
    "&$cardHeaderImage": {
      margin: "0 !important",
    },
  },

  cardHeaderImage: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    borderRadius: "6px",
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    "@media (max-width: 1340px)": {
      marginLeft: "-20px",
    },
    "@media (max-width: 1280px)": {
      marginLeft: "-15px",
    },

    "@media (max-width: 990px)": {
      marginLeft: "-15px",
    },

    "@media (max-width: 960px)": {
      marginLeft: "25px",
    },
    "@media (max-width: 599px)": {
      marginLeft: "15%",
    },
    "@media (max-width: 280px)": {
      marginLeft: "-10px",
    },

    "@media (min-width: 1920px)": {
      marginLeft: "-15px",
    },
    "& img": {
      height: "auto",
      maxHeight: "250px",
      width: "auto",
      maxWidth: "250px",
      borderRadius: "6px",
      pointerEvents: "none",
      boxShadow:
        "0 5px 15px -8px rgba(" +
        hexToRgb(blackColor) +
        ", 0.24), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
    "& a": {
      display: "block",
    },
  },
  cardHeaderImageCatFeatured: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    marginLeft: "30px",
    marginRight: "15px",
    marginTop: "-30px",
    borderRadius: "6px",
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    "@media (max-width: 1280px)": {
      marginLeft: "11px",
    },

    "& img": {
      height: "auto",
      maxHeight: "300px",
      width: "auto",
      maxWidth: "300px",
      borderRadius: "6px",
      pointerEvents: "none",
      boxShadow:
        "0 5px 15px -8px rgba(" +
        hexToRgb(blackColor) +
        ", 0.24), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
      "@media (max-width: 1920px)": {
        maxWidth: "300px",
      },
      "@media (max-width: 1680px)": {
        maxWidth: "350px",
      },
      "@media (max-width: 1600px)": {
        maxWidth: "350px",
      },
      "@media (max-width: 1340px)": {
        maxWidth: "260px",
      },
      "@media (max-width: 1280px)": {
        maxWidth: "270px",
      },
    },
    "& a": {
      display: "block",
    },
  },
  noShadow: {
    "& img": {
      boxShadow: "none !important",
    },
  },
  cardHeaderContact: {
    margin: "0 15px",
    marginTop: "-20px",
  },
  cardHeaderSignup: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    width: "100%",
    marginBottom: "15px",
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
};

export default cardHeaderStyle;
