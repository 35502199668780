import { useEffect, React } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadAbout } from "state/about";

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Markup } from "interweave";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStylesEcommerce = makeStyles(styles);

export default function FooterComponent() {
  const classesEC = useStylesEcommerce();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAbout());
  }, [dispatch]);

  const about = useSelector((state) => state.about);
  return (
    <Footer
      theme="dark"
      content={
        <div>
          <div className={classesEC.left}>
            {/*              <List className={classesEC.list}>
            <ListItem className={classesEC.inlineBlock}>
              <a
                href="http://blog.creative-tim.com/?ref=mkpr-e-ecommerce"
                target="_blank"
                className={classesEC.block}
                rel="noreferrer"
              >
                Blog
              </a>
            </ListItem>
            <ListItem className={classesEC.inlineBlock}>
              <a
                href="https://www.creative-tim.com/presentation?ref=mkpr-e-ecommerce"
                target="_blank"
                className={classesEC.block}
                rel="noreferrer"
              >
                Presentation
              </a>
            </ListItem>
            <ListItem className={classesEC.inlineBlock}>
              <a
                href="#pablito"
                onClick={(e) => e.preventDefault()}
                className={classesEC.block}
              >
                Discover
              </a>
            </ListItem>
            <ListItem className={classesEC.inlineBlock}>
              <a
                href="#pablito"
                onClick={(e) => e.preventDefault()}
                className={classesEC.block}
              >
                Payment
              </a>
            </ListItem>
            <ListItem className={classesEC.inlineBlock}>
              <a
                href="https://www.creative-tim.com/contact-us?ref=mkpr-e-ecommerce"
                target="_blank"
                className={classesEC.block}
                rel="noreferrer"
              >
                Contact us
              </a>
            </ListItem>
          </List>  */}
          </div>
          <div className={classesEC.right}>
            Copyright &copy; {1900 + new Date().getYear()}{" "}
            <a
              href="https://technotion.pt"
              target="_blank"
              className={classesEC.aClasses}
              rel="noreferrer"
            >
              TECHNOTION
            </a>{" "}
            All Rights Reserved.
          </div>
        </div>
      }
    >
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Sobre nós</h5>
          <Markup content={about.presentation} />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Contactos</h5>
          <div className={classesEC.socialFeed}>
            <div>
              <i className="fas fa-map-pin" />
              <p>{about.address}</p>
            </div>
            <div>
              <i className="fas fa-phone" />
              <p>{about.phone}</p>
            </div>
            <div>
              <i className="fas fa-at" />
              <p>{about.email}</p>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          {/* <h5>Instagram Feed</h5> */}
        </GridItem>
      </GridContainer>
    </Footer>
  );
}
