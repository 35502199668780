import {
  container,
  mrAuto,
  mlAuto,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
  container,
  textCenter: {
    textAlign: "center",
  },
  aboutDescription: {
    padding: "40px 0 0 0",
  },
  /*   description: {
    "&, & p ": {
      color: whiteColor,
      fontSize: "25px !important",
    },
  }, */
  mrAuto,
  mlAuto,
  description: {
    marginBottom: "40px",
    color: grayColor[0],
    "&, & p ": {
      fontSize: "20px !important",
    },
  },
};

export default descriptionStyle;
