import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const initialState = {
  total: 0,
  quantity: 0,
  products: [],
  shippingMethods: [],
  shippingMethodsChoise: null,
  loading: false,
  status: null,
  refOrder: null,
};

const slice = createSlice({
  name: "cartProduts",
  initialState: initialState,
  reducers: {
    addToCart: (cart, action) => {
      if (cart.status != null) {
        cart.status = null;
        cart.refOrder = null;
      }
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index === -1) cart.products.push(action.payload);
      else cart.products[index].quantity = cart.products[index].quantity + 1;

      cart.quantity = cart.quantity + 1;
      cart.total += action.payload.price;
    },
    removeFromCart: (cart, action) => {
      let qtd = 0;
      cart.products = cart.products.filter(function (item) {
        if (item.id !== action.payload.id) {
          return item;
        } else qtd = item.quantity;
      });

      cart.quantity -= qtd;
      cart.total -= action.payload.price * qtd;
    },
    addQuantity: (cart, action) => {
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index >= 0) cart.products[index].quantity += 1;

      cart.quantity += 1;
      cart.total += action.payload.price;
    },
    subtractQuantity: (cart, action) => {
      const index = cart.products.findIndex(
        (product) => product.id === action.payload.id
      );
      let product = cart.products[index];
      if (product)
        if (product.quantity !== 1) {
          product.quantity -= 1;
          cart.quantity -= 1;
          cart.total -= action.payload.price;
        } else {
          product.quantity = 1;
        }
    },
    emptyCart: (cart) => {
      cart.products = [];
    },
    orderRequested: (cart) => {
      cart.loading = true;
    },
    orderStatusReceived: (cart, action) => {
      cart.total = 0;
      cart.quantity = 0;
      cart.products = [];
      console.log(cart.products.length);
      cart.status = action.payload.status;
      cart.refOrder = action.payload.order.code;
      cart.loading = false;
    },
    orderFailed: (cart) => {
      cart.loading = false;
    },

    shippingMethodsReceived: (cart, action) => {
      console.log(action.payload);
      cart.shippingMethods = action.payload.data;
      if (cart.shippingMethodsChoise) {
        cart.total -= cart.shippingMethodsChoise.price;
        cart.shippingMethodsChoise = null;
      }
      cart.loading = false;
    },
    setShippingMethodsChoise: (cart, action) => {
      const index = cart.shippingMethods.findIndex(
        (method) => method.id === action.payload.method_id
      );
      if (cart.shippingMethodsChoise)
        cart.total -= cart.shippingMethodsChoise.price;

      cart.shippingMethodsChoise = cart.shippingMethods[index];
      cart.total += cart.shippingMethods[index].price;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  addQuantity,
  subtractQuantity,
  emptyCart,
  orderRequested,
  orderStatusReceived,
  orderFailed,
  shippingMethodsReceived,
  setShippingMethodsChoise,
} = slice.actions;
export default slice.reducer;

const urlSubmitOrder = "orders";
const urlShippingMethods = "/shipping-methods";

export const submitOrder =
  ({ data: data }) =>
  (dispatch, getState) => {
    const { products } = getState().cart;
    data.products = products;
    console.log("submitOrder");
    dispatch(
      apiCallBegan({
        url: urlSubmitOrder,
        method: "POST",
        data,
        onStart: orderRequested.type,
        onSuccess: orderStatusReceived.type,
        onError: orderFailed.type,
      })
    );
  };

export const getShippingMethods = () => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: urlShippingMethods,

      onStart: orderRequested.type,
      onSuccess: shippingMethodsReceived.type,
      onError: orderFailed.type,
    })
  );
};

export const setShippingMethods =
  ({ value: method_id }) =>
  (dispatch) => {
    dispatch(setShippingMethodsChoise({ method_id }));
  };
