/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { secondaryColor } from "assets/jss/material-kit-pro-react.js";
import placeholder from "assets/img/placeholder.png";

//redux
import { useDispatch } from "react-redux";
import { addToCart } from "state/cart";
import { useSelector } from "react-redux";
import { loadProduct } from "state/products";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
const useStyles = makeStyles(styles);

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "right",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  cartIconAcive: {
    color: secondaryColor,
  },

  placeholerImg: {
    padding: "75px",
  },
}));

const CardProduct = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const classeCart = useStyle();
  const {
    id,
    slug,
    sku,
    name,
    category_name,
    thumbnail,
    price,
    old_price,
    label,
    discount,
    baseURL,
  } = props;

  const addProduct = () => {
    setCart(true);
    let urlImage;
    if (thumbnail) urlImage = baseURL + thumbnail;
    else urlImage = thumbnail;
    dispatch(
      addToCart({
        sku: sku,
        id: id,
        name: name["pt"],
        price: price,
        total_price: price,
        thumbnail: urlImage,
        quantity: 1,
      })
    );
  };

  const cart = useSelector((state) => state.cart.products);

  const [CartActive, setCart] = useState(false);
  let active = false;
  if (
    cart.filter(function (e) {
      return e.id === id;
    }).length > 0
  ) {
    active = true;
  }

  return (
    <Card plain product>
      <CardHeader noShadow image>
        {label ? (
          <div className={classeCart.root}>
            <Chip
              label={
                <Typography variant="body2" align="left">
                  {label.pt}
                </Typography>
              }
              variant="outlined"
              color="primary"
            />
          </div>
        ) : (
          ""
        )}
        <Link
          onClick={() => dispatch(loadProduct({ id: slug }))}
          to={{ pathname: `p-page/${slug}` }}
        >
          {thumbnail ? (
            <img src={`${baseURL}${thumbnail}`} />
          ) : (
            <img className={classeCart.placeholerImg} src={`${placeholder}`} />
          )}
        </Link>
      </CardHeader>
      <CardBody plain>
        <Link
          onClick={() => dispatch(loadProduct({ id: slug }))}
          to={{ pathname: `p-page/${slug}` }}
        >
          <h4 className={classes.cardTitle}>{name["pt"]}</h4>
        </Link>
        <div>
          <p className={classes.description}>{sku}</p>
          {discount != 0 ? (
            <Grid container justify="flex-end" alignItems="flex-end">
              <Avatar
                style={{
                  fontWeight: "500",
                  fontSize: "15px",
                  marginLeft: "-15px",
                  marginTop: "-15px",
                  marginBottom: "-25px",
                  backgroundColor: "#F00026",
                }}
              >
                -{discount}%
              </Avatar>
            </Grid>
          ) : (
            ""
          )}
        </div>
        <p className={classes.description}>{category_name["pt"]}</p>
      </CardBody>
      <CardFooter plain className={classes.justifyContentBetween}>
        <div className={classes.priceContainer}>
          <Box m={1}>
            {price ? (
              <NumberFormat
                value={price}
                className={classes.price}
                displayType={"text"}
                decimalSeparator=","
                suffix={"€"}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            ) : (
              <Typography variant="body2" align="left">
                Preço sob Consulta
              </Typography>
            )}
          </Box>
          {old_price ? (
            <Box m={1}>
              <div>
                <span
                  style={{
                    marginRight: "6px",
                    fontSize: "10px",
                  }}
                >
                  PVPR
                </span>

                <NumberFormat
                  value={old_price}
                  className={classes.oldPrice}
                  displayType={"text"}
                  decimalSeparator=","
                  suffix={"€"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </div>{" "}
            </Box>
          ) : (
            ""
          )}
        </div>

        {price ? (
          <Tooltip
            id="tooltip-top"
            title="Guardar no carinho"
            placement="left"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton className={classes.pullRight} onClick={addProduct}>
              <ShoppingCartIcon
                className={CartActive || active ? classeCart.cartIconAcive : ""}
              />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </CardFooter>
    </Card>
  );
};

CardProduct.propTypes = {
  name: PropTypes.shape({
    pt: PropTypes.string,
  }),
  category_name: PropTypes.shape({
    pt: PropTypes.string,
  }),
  sku: PropTypes.string,
  slug: PropTypes.string,
  baseURL: PropTypes.string,
  label: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.shape({
    pt: PropTypes.string,
  }),
  thumbnail: PropTypes.string,
  price: PropTypes.number,
  old_price: PropTypes.number,
  discount: PropTypes.number,
  id: PropTypes.number,
  addProductToCart: PropTypes.func,
};

export default CardProduct;
