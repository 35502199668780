import { React } from "react";

import Header from "./HeaderCustom.js";
import Slider from "./Banner/Slider";
import ProductsFeaturedComponent from "./ProductsFeaturedComponent";
import CategoriesFeaturedComponent from "./CategoriesFeaturedComponent";
import FooterComponent from "./FooterComponent";

// @material-ui/core components
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";

const useStylesEcommerce = makeStyles(styles);

export default function Home() {
  const classes = useStylesEcommerce();

  return (
    <div>
      <Header />

      <Slider />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <ProductsFeaturedComponent />
        <CategoriesFeaturedComponent />
      </div>

      <FooterComponent />
    </div>
  );
}
