/* eslint-disable no-unused-vars */
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { Markup } from "interweave";
import InfoArea from "components/InfoArea/InfoArea.js";
import map from "assets/img/sections/map.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles(descriptionStyle);
const useStyle = makeStyles(featuresStyle);

export default function SectionDescription() {
  const classes = useStyles();
  const classesF = useStyle();
  const [elevationValue, setState] = useState(1);
  const about = useSelector((state) => state.about);

  return (
    <>
      <div className={classNames(classes.aboutDescription, classes.textCenter)}>
        {/*       <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <Box m={3}>
            <h5 className={classes.description}>
              <Markup content={about.presentation} />
            </h5>
          </Box>
        </GridItem>
      </GridContainer> */}
      </div>
      <div className={classesF.features3}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/R.+da+Barranha+455,+4460-258+Sra.+da+Hora/@41.1874958,-8.6674568,17z/data=!3m1!4b1!4m5!3m4!1s0xd246f572a1703e9:0x790608f2341ee5c3!8m2!3d41.1874918!4d-8.6652681"
            >
              <Paper
                elevation={elevationValue}
                onMouseOver={() => setState(24)}
                onMouseOut={() => setState(1)}
              >
                <div className={classesF.phoneContainer}>
                  <img src={map} alt="..." />
                </div>
              </Paper>
            </a>
          </GridItem>
          <GridItem xs={12} sm={4} md={4} className={classesF.info}>
            <h2 className={classesF.title}>Visite a nossa Loja!</h2>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/R.+da+Barranha+455,+4460-258+Sra.+da+Hora/@41.1874958,-8.6674568,17z/data=!3m1!4b1!4m5!3m4!1s0xd246f572a1703e9:0x790608f2341ee5c3!8m2!3d41.1874918!4d-8.6652681"
            >
              <InfoArea
                className={classesF.infoArea}
                icon={LocationOnIcon}
                title="Morada"
                description={about.address}
                iconColor="primary"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/informag.informatica"
            >
              <InfoArea
                className={classesF.infoArea}
                icon={FacebookIcon}
                title="Também pode encontrar-nos no facebook!"
                description="@informag.informatica"
                iconColor="primary"
              />
            </a>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}
